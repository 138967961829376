import { Row, Col, Image, Container, Badge, ListGroup } from 'react-bootstrap';
import { 
    SiFlask, SiReact, SiCplusplus, SiCsharp, SiJavascript, SiPython, SiBootstrap, SiHtml5, SiExpress, 
    SiNodedotjs, SiDocker, SiJquery, SiPhp, SiJira, SiKeras, SiTensorflow, SiNumpy, SiCss3
} from 'react-icons/si';
import React from 'react';


const CSharpBadge = () => {
    return (
        <Badge bg="primary"><SiCsharp/> C#</Badge>
    )
};

const ReactBadge = () => {
    return (
        <Badge bg="primary"><SiReact/> React</Badge>
    )
}

const BootstrapBadge = () => {
    return (
        <Badge bg="danger"><SiBootstrap/> Bootstrap</Badge>
    )
}


const ExpressBadge = () => {
    return (
        <Badge bg="white" text="dark"><SiExpress/> Express</Badge>
    )
}

const Software = () => {
    return (
        <>
        <Container>
            <div>
                <Image
                    className="mx-auto d-block"
                    src="/img/software.gif" fluid 
                    width="100px"
                    max-width="100%"
                />
            </div>
            <h1 className='glow'>Software</h1>
            <p>
                Can't call myself a developer without a few developments.
            </p>
            <p>
                I enjoy building scalable software.
            </p>
            <Container className="my-5">
                <h2 align="center" className='glow'>My Creations</h2>
                <hr/>
                <Row>
                    <Col xs={12} sm={6} md={6} className="justify-content-center d-flex flex-column my-5">
                    <h2><a href="https://github.com/MightyK/CellDBMS" rel="noreferrer" target="_blank">Cell Phone DBMS</a></h2>
                        <p>
                            A CVS parser used to extract and operate on cell phone data.
                        </p>
                        <p>
                            Final project for Principles of Programming Language course.
                        </p>
                        <div>
                            <CSharpBadge/>
                        </div>
                    </Col>
                    <Col xs={12} sm={6} md={6} className="justify-content-center align-items-center d-flex flex-column my-5">
                        <Image src="./img/software/DBMS.png" fluid width="90%" rounded/>
                    </Col>
                </Row>
                <hr/>
                <Row>
                    <Col xs={12} sm={6} md={6} className="justify-content-center d-flex flex-column my-5">
                    <h2><a href="https://sensatiable-v2-daba7050f8d7.herokuapp.com/" rel="noreferrer" target="_blank">Sensatiable</a></h2>
                        <p>
                            A web application which employs the Yelp API to query restaurants in a given area based on the type of food served.
                        </p>
                        <p>
                            Final project for Principles of Software Engineering.
                        </p>
                        <div>
                            <ReactBadge/> <ExpressBadge/>
                        </div>
                    </Col>
                    <Col xs={12} sm={6} md={6} className="justify-content-center align-items-center d-flex flex-column my-5">
                        <Image src="./img/software/Sensatiable.png" fluid width="90%" rounded/>
                    </Col>
                </Row>
                <hr/>
                <Row>
                    <Col xs={12} sm={6} md={6} className="justify-content-center d-flex flex-column my-5">
                    <h2><a href="/" rel="noreferrer" target="_blank">Personal Website</a></h2>
                        <p>
                            Deja vu!
                        </p>
                        <div>
                            <ReactBadge/> <BootstrapBadge/>
                        </div>
                    </Col>
                    <Col xs={12} sm={6} md={6} className="justify-content-center align-items-center d-flex flex-column my-5">
                        <Image src="./img/software/PersonalSite.png" fluid width="90%" rounded/>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col xs={12} sm={12} md={12} className="my-5">
                        <h2 align="center" className='glow'>Technologies I Use</h2>
                        <hr/>
                        <div className="my-5 ">
                            <ListGroup a="ul" className='d-flex justify-content-center align-items-center'>
                                <Row>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <li>
                                            <SiCplusplus/> C++, <SiCsharp/> C#
                                        </li>
                                        <li>
                                            <SiHtml5/> HTML, <SiCss3/> CSS, <SiJavascript/> JavaScript
                                        </li>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <li>
                                            <SiReact/> React, <SiBootstrap/> Bootstrap
                                        </li>
                                        <li>
                                            <SiNodedotjs/> Node.js, <SiExpress/> Express
                                        </li>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <li>
                                            <SiPython/> Python, <SiFlask/> Flask
                                        </li>
                                        <li>
                                            <SiKeras/> Keras, <SiTensorflow/> TensorFlow, <SiNumpy/> NumPy
                                        </li>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <li>
                                            <SiPhp/> PHP, <SiJquery/> jQuery
                                        </li>
                                        <li>
                                            <SiDocker/> Docker, <SiJira/> Jira
                                        </li>
                                    </Col>
                                </Row>
                            </ListGroup>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>
        </>
    )
}

export default Software;