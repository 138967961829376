// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fade-appear,
.fade-enter {
    opacity: 0;
    z-index: 1;
}
.fade-appear-active,
.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 500ms linear 250ms;
}
.fade-exit {
    opacity: 1;
}
.fade-exit.fade-exit-active {
    opacity: 0;
    transition: opacity 200ms linear;
}`, "",{"version":3,"sources":["webpack://./src/Animations.css"],"names":[],"mappings":"AAAA;;IAEI,UAAU;IACV,UAAU;AACd;AACA;;IAEI,UAAU;IACV,sCAAsC;AAC1C;AACA;IACI,UAAU;AACd;AACA;IACI,UAAU;IACV,gCAAgC;AACpC","sourcesContent":[".fade-appear,\n.fade-enter {\n    opacity: 0;\n    z-index: 1;\n}\n.fade-appear-active,\n.fade-enter.fade-enter-active {\n    opacity: 1;\n    transition: opacity 500ms linear 250ms;\n}\n.fade-exit {\n    opacity: 1;\n}\n.fade-exit.fade-exit-active {\n    opacity: 0;\n    transition: opacity 200ms linear;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
