import React from 'react';
import { Container, Row, Col, Image} from 'react-bootstrap';
import {
  SiCplusplus, SiCsharp, SiHtml5, SiCss3, SiJavascript, SiReact, SiBootstrap,
  SiNodedotjs, SiExpress, SiPython, SiFlask, SiKeras, SiTensorflow, SiNumpy,
  SiPhp, SiJquery, SiDocker, SiJira, SiHeroku, SiGit, SiGithub
} from 'react-icons/si';

import '../App.css';

const Resume = () => {
  return (
    <>
      <Container>
        <div className='image-container mx-auto'>
          <Image
            className="profile-image"
            src="./img/Profile.png"
            fluid
            width="100px"
            max-width="100%"
            roundedCircle
          />
          <Image
            className="overlay-image"
            src="/img/ravenclaw.gif" fluid 
            width="100px"
            max-width="100%"
          />
        </div>
        <h1 className='glow text-center name'>Kadeem Mighty</h1>
        <p className="text-center">
          Dallas, TX | (561) 729-6222 | <a href="mailto:" target="_blank" rel="noreferrer">KadeemMighty@gmail.com</a> | <a href="https://linkedin.com/in/kmighty" target="_blank" rel="noreferrer">linkedin.com/in/kmighty</a>
        </p>
        <Container className="my-5">
          <h2 align="center" className='glow'>Education</h2>
          <hr />
          <Row>
            <Col>
              <h3>Florida Atlantic University, Boca Raton, FL</h3>
            </Col>
            <Col>
              <p>Bachelor of Science in Computer Science, May 2025</p>
            </Col>
           </Row>
           <br/>
           <Row>
            <Col>
              <h3>Palm Beach State College, Lake Worth, FL</h3>
            </Col>
            <Col>
              <p>Associate in Arts, December 2022</p>
            </Col>
          </Row>
          <br/>
          <h2 align="center" className='glow'>Coursework</h2>
          <hr />

              <ul>
          <Row>
                <Col xs={12} sm={6} md={6} lg={4} className="my-5">
                    <li>Data Structures & Algorithms</li>
                    <li>Design & Analysis of Algorithms</li>
                    <li>Mobile App Projects</li>
                    <li>Principles of Software Engineering</li>
                    <li>Principles of Programming Languages</li>
                </Col>
                <Col xs={12} sm={6} md={6} lg={4} className="my-5">
                    <li>Intro to Deep Learning</li>
                    <li>Stochastic Processes</li>
                    <li>Theory of Computation</li>
                    <li>Intro Data Science Analytics</li>
                    <li>Database Structures</li>
                </Col>
                <Col xs={12} sm={6} md={6} lg={4} className="my-5">
                    <li>Intro to Internet Computing</li>
                    <li>Communication Networks</li>
                    <li>Computer Architecture</li>
                    <li>Computer Operating Systems</li>
                    <li>Foundations of Cybersecurity</li>
                </Col>
          </Row>
              </ul>

          <h2 align="center" className='glow'>Projects</h2>
          <hr />
          <Row>
            <Col xs={12} sm={6} md={6} className="justify-content-center d-flex flex-column my-5">
              <h3><a href="https://sensatiable-v2-daba7050f8d7.herokuapp.com/" rel="noreferrer" target="_blank">Sensatiable</a></h3>
              <p>A web application which employs the Yelp API to query restaurants in a given area based on the type of food served.</p>
              <p>Final project for Principles of Software Engineering.</p>
              <div>
              JavaScript<SiJavascript /> Node.js<SiNodedotjs /> React<SiReact /> Heroku<SiHeroku /> Git<SiGit /> GitHub<SiGithub />
              </div>
            </Col>
            <Col xs={12} sm={6} md={6} className="justify-content-center align-items-center d-flex flex-column my-5">
              <Image src="./img/software/Sensatiable.png" fluid width="90%" rounded />
            </Col>
          </Row>

          <Row>
            <Col xs={12} sm={6} md={6} className="justify-content-center d-flex flex-column my-5">
              <h3><a href="https://github.com/MightyK/CellDBMS" rel="noreferrer" target="_blank">Cell DBMS</a></h3>
              <p>Developed a C#-based Cell Phone Database Management System for efficient CSV data ingestion, parsing, and cleaning using Regex and TextFieldParser.</p>
              <p>Final project for Principles of Programming Language course.</p>
              <div>
              C#<SiCsharp /> Git<SiGit /> GitHub<SiGithub />
              </div>
            </Col>
            <Col xs={12} sm={6} md={6} className="justify-content-center align-items-center d-flex flex-column my-5">
              <Image src="./img/software/DBMS.png" fluid width="90%" rounded />
            </Col>
          </Row>
          <br />
          <h2 align="center" className='glow'>Certifications</h2>
          <hr />
          <Row>
            <Col>
              <h3>J.P. Morgan Chase & Co. Software Engineering Virtual Experience</h3>
              <p>Python, Typescript, Git | August 2023</p>
              <p>Implemented graphical interface using stock price data feeds. Utilized J.P. Morgan Chase & Co. internal tools and frameworks. Visualized stock price data for traders.</p>
              <p><a href="https://forage-uploads-prod.s3.amazonaws.com/completion-certificates/J.P.%20Morgan/R5iK7HMxJGBgaSbvk_J.P.%20Morgan_bQkDLHWuoM57bhReE_1691539549838_completion_certificate.pdf" target="_blank" rel="noreferrer">Certificate of Completion Link</a></p>
              <h3>J.P. Morgan Chase & Co. Agile Job Simulation on Forage</h3>
              <p>Trello, Jira, Slack, Agile | January 2024</p>
              <p>Drafted user stories using Trello and prioritized them according to the estimated level of effort required. Ran the Scrum team’s daily standup to effectively plan the day and energize the team members. Conducted a sprint review to gather feedback on the Scrum’s work. Conducted a sprint retrospective to align on opportunities for improvements on future sprints.</p>
              <p><a href="https://forage-uploads-prod.s3.amazonaws.com/completion-certificates/J.P.%20Morgan/5QiaMtZ4k8ngYKn4D_JPMorgan%20Chase%20&%20Co._bQkDLHWuoM57bhReE_1704145641561_completion_certificate.pdf" target="_blank" rel="noreferrer">Certificate of Completion Link</a></p>
            </Col>
          </Row>
          <h2 align="center" className='glow'>Technical Skills</h2>
          <hr />
          <Row>
            <Col>
              <ul>
                <li>Languages: C, C++, C#, Python, JavaScript, SQL</li>
                <li>Frameworks: React, Node.js</li>
                <li>Developer Tools: Git, VS Code, Oracle, Xcode, CLion, PyCharm, Trello, Slack, GitHub, Jira, Terminal, Jupyter Notebook, Google Colab</li>
                <li>Libraries: Pandas, NumPy, Keras, TensorFlow, jQuery</li>
              </ul>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default Resume;
