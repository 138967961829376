import { Container, Image, ListGroup } from 'react-bootstrap';
import { ReactTyped } from 'react-typed';

import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

const EducationElement = ({ title, subtitle, date, description }) => (
    <VerticalTimelineElement
      className="vertical-timeline-element--school"
      contentStyle={{ background: 'rgb(0, 51, 51)', color: '#fff' }}
      contentArrowStyle={{ borderRight: '7px solid  rgb(0, 51, 51)' }}
      date={date}
      dateClassName='glow'
      iconStyle={{ background: 'rgb(0, 51, 51)', color: '#fff' }}
      icon={<i className="fas fa-graduation-cap center-icon"></i>}
    >
      <h3 className="vertical-timeline-element-title glow">{title}</h3>
      <p className="vertical-timeline-element-subtitle">{subtitle}</p>
      <hr />
      {
          description.map((item) => (
              <p>{item}</p>
          ))
      }
  </VerticalTimelineElement>
);

const CertificationElement = ({ title, subtitle, date, description }) => (
  <VerticalTimelineElement
    className="vertical-timeline-element--school"
    contentStyle={{ background: 'rgb(102, 0, 0)', color: '#fff' }}
    contentArrowStyle={{ borderRight: '7px solid  rgb(102, 0, 0)' }}
    date={date}
    dateClassName='glow'
    iconStyle={{ background: 'rgb(102, 0, 0)', color: '#fff' }}
    icon={<i className="fas fa-scroll center-icon"></i>}
  >
    <h3 className="vertical-timeline-element-title glow">{title}</h3>
    <p className="vertical-timeline-element-subtitle">{subtitle}</p>
    <hr />
    {
        description.map((item) => (
            <p>{item}</p>
        ))
    }
  </VerticalTimelineElement>
);

const WorkElement = ({ title, subtitle, date, description }) => (
  <VerticalTimelineElement
    className="vertical-timeline-element--school"
    contentStyle={{ background: 'rgb(51, 51, 102)', color: '#fff' }}
    contentArrowStyle={{ borderRight: '7px solid  rgb(51, 51, 102)' }}
    date={date}
    dateClassName='glow'
    iconStyle={{ background: 'rgb(51, 51, 102)', color: '#fff' }}
    icon={<i className="fas fa-briefcase center-icon"></i>}
  >
    <h3 className="vertical-timeline-element-title glow">{title}</h3>
    <p className="vertical-timeline-element-subtitle">{subtitle}</p>
    <hr />
    {
        description.map((item) => (
            <p>{item}</p>
        ))
    }
  </VerticalTimelineElement>
);

const About = () => {
  return (
    <>
    <Container>
      <Container>
        <div>
          <Image
              className="mx-auto d-block"
              src="/img/Intro.gif" fluid
              width="100px"
              max-width="100%"
          />
        </div>
        <h1 className='glow'>About</h1>
        <div>
          <p>Hi, I'm Kadeem Mighty.</p>
          <p>I am a <ReactTyped
            strings={[
              'Computer Science student.',
              'software developer.',
              'human.',
            ]}
            typeSpeed={40}
            backSpeed={50}
            loop />
          </p>
        </div>
      </Container>
      <Container className="my-5">
        <h2 align="center" className='glow'>Timeline</h2>
        <hr/>
        <VerticalTimeline>
          <EducationElement
            title="B.S. Computer Science"
            subtitle="Florida Atlantic University"
            date="Jan. 2023-May 2025"
            description={["Senior Undergraduate.",
               "Relevant Coursework:",
              "Data Structures & Algorithms • Design & Analysis of Algorithms • Mobile App Projects • Principles of Software Engineering • Principles of Programming Languages • Intro to Deep Learning • Stochastic Processes • Theory of Computation • Intro Data Science Analytics • Database Structures • Intro to Internet Computing • Communication Networks • Computer Architecture • Computer Operating Systems • Foundations of Cybersecurity"
              ]}
          />
          <CertificationElement
            title="Agile Job Simulation"
            subtitle="Forage - J.P. Morgan Chase & Co."
            date="Jan. 2024"
            description={["Agile Methodologies certification.", <a href='https://forage-uploads-prod.s3.amazonaws.com/completion-certificates/J.P.%20Morgan/5QiaMtZ4k8ngYKn4D_JPMorgan%20Chase%20&%20Co._bQkDLHWuoM57bhReE_1704145641561_completion_certificate.pdf' target='_blank'>Link</a>]}
          />
          <CertificationElement
            title="Software Engineering Virtual Experience"
            subtitle="Forage - J.P. Morgan Chase & Co."
            date="Aug. 2023"
            description={["Software Engineering certification.", <a href='https://forage-uploads-prod.s3.amazonaws.com/completion-certificates/J.P.%20Morgan/R5iK7HMxJGBgaSbvk_J.P.%20Morgan_bQkDLHWuoM57bhReE_1691539549838_completion_certificate.pdf' target='_blank'>Link</a>]}
          />
          <EducationElement
            title="Associate in Arts"
            subtitle="Palm Beach State College"
            date="Jan. 2021-Dec. 2022"
            description={[
              "Completed general education courses.",
              "Relevant Coursework:",
              "Calculus w/ Analytical Geometry I & II • General Physics w/ Calculus I & II• Intro to Programming in C."
            ]}
          />
        </VerticalTimeline>
      </Container>
    </Container>
    </>
  );
}

export default About